// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.aw32cGRns2wBJB5J17B3 {\n  margin-bottom: 1em;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.P1SzzfmyosDdkDOBJx0L {\n  background: #ddd;\n}\n\n.iLqgTTKyT4xtVK5nioql {\n}\n\ndiv.aw32cGRns2wBJB5J17B3 input:not([type=\"checkbox\"]):not([type=\"submit\"]),\ndiv.aw32cGRns2wBJB5J17B3 textarea {\n  width: 100%;\n}\n\ndiv.aw32cGRns2wBJB5J17B3 input:not([type=\"checkbox\"]) {\n  height: 30px;\n  min-height: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/InputWidget/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AACA;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["div.field {\n  margin-bottom: 1em;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.readonly {\n  background: #ddd;\n}\n\n.writeable {\n}\n\ndiv.field input:not([type=\"checkbox\"]):not([type=\"submit\"]),\ndiv.field textarea {\n  width: 100%;\n}\n\ndiv.field input:not([type=\"checkbox\"]) {\n  height: 30px;\n  min-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "aw32cGRns2wBJB5J17B3",
	"readonly": "P1SzzfmyosDdkDOBJx0L",
	"writeable": "iLqgTTKyT4xtVK5nioql"
};
export default ___CSS_LOADER_EXPORT___;
