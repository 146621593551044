// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.hAl6b5Wr1jSXeuwM0dGE {\n  margin-top: 20px;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.QZBpMVFy1ClKIW1Irmwp {\n  background: var(--color7);\n}\n\nbutton.Fuf3mcjnpkYEiUuoQmvS {\n  margin-right: 8px;\n  display: inline-block;\n  height: 30px;\n  padding: 0 20px;\n  color: var(--color4);\n  text-align: center;\n  line-height: 30px;\n  text-decoration: none;\n  white-space: nowrap;\n  background-color: transparent;\n  border-radius: 4px;\n  border: 1px solid var(--color6);\n  cursor: pointer;\n  box-sizing: border-box;\n  background: var(--color1);\n}\n\nbutton.Fuf3mcjnpkYEiUuoQmvS:hover {\n  background-color: var(--color2);\n  outline: 0;\n}\n\nbutton.Fuf3mcjnpkYEiUuoQmvS:visited {\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/ButtonWidget/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,+BAA+B;EAC/B,eAAe;EACf,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["div.field {\n  margin-top: 20px;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.readonly {\n  background: var(--color7);\n}\n\nbutton.ButtonWidget {\n  margin-right: 8px;\n  display: inline-block;\n  height: 30px;\n  padding: 0 20px;\n  color: var(--color4);\n  text-align: center;\n  line-height: 30px;\n  text-decoration: none;\n  white-space: nowrap;\n  background-color: transparent;\n  border-radius: 4px;\n  border: 1px solid var(--color6);\n  cursor: pointer;\n  box-sizing: border-box;\n  background: var(--color1);\n}\n\nbutton.ButtonWidget:hover {\n  background-color: var(--color2);\n  outline: 0;\n}\n\nbutton.ButtonWidget:visited {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "hAl6b5Wr1jSXeuwM0dGE",
	"readonly": "QZBpMVFy1ClKIW1Irmwp",
	"ButtonWidget": "Fuf3mcjnpkYEiUuoQmvS"
};
export default ___CSS_LOADER_EXPORT___;
