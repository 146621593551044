// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\r\nbody,\r\ndiv#Vrlpy8w24D4QymXwa6HB,\r\n.kqBHqtUPXvepi7RrI3RW {\r\n  font-family: Arial, Helvetica, sans-serif;\r\n  margin: 0;\r\n  padding: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.kqBHqtUPXvepi7RrI3RW {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.MHwxcAUBVOARsvke9hkF {\r\n  margin-top: 56px; /* header height */\r\n  padding: 0em 1em;\r\n  flex: 1 1;\r\n  position:relative;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;;;;EAIE,yCAAyC;EACzC,SAAS;EACT,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB,EAAE,kBAAkB;EACpC,gBAAgB;EAChB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":["html,\r\nbody,\r\ndiv#root,\r\n.app {\r\n  font-family: Arial, Helvetica, sans-serif;\r\n  margin: 0;\r\n  padding: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.app {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.main {\r\n  margin-top: 56px; /* header height */\r\n  padding: 0em 1em;\r\n  flex: 1 1;\r\n  position:relative;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Vrlpy8w24D4QymXwa6HB",
	"app": "kqBHqtUPXvepi7RrI3RW",
	"main": "MHwxcAUBVOARsvke9hkF"
};
export default ___CSS_LOADER_EXPORT___;
