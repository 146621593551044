// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VRVnzOkUnwSwvq_wKYuA {\r\n    padding: 8px 12px;\r\n    border: 1px solid #425363;\r\n    border-radius: 4px;\r\n}\r\n\r\n\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/CounterComponent/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".counter {\r\n    padding: 8px 12px;\r\n    border: 1px solid #425363;\r\n    border-radius: 4px;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": "VRVnzOkUnwSwvq_wKYuA"
};
export default ___CSS_LOADER_EXPORT___;
