// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xg8uzB2H1wqMNaUM7vJX {\r\n    margin: auto;\r\n    width: 200px;\r\n    text-align: center;\r\n}", "",{"version":3,"sources":["webpack://./src/routes/LoginRoute/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".centredContainer {\r\n    margin: auto;\r\n    width: 200px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centredContainer": "Xg8uzB2H1wqMNaUM7vJX"
};
export default ___CSS_LOADER_EXPORT___;
