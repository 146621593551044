// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rDQEIaq6XCtHYqzEkGu2 {\r\n    height: 30px;\r\n    color: var(--color4);\r\n    text-align: center;\r\n    text-decoration: none;\r\n    white-space: nowrap;\r\n    box-sizing: border-box;\r\n    border: 2px dashed grey; /* the dash box */\r\n    background: lightcyan;\r\n    color: dimgray;\r\n    min-height: 30px; /* minimum height */\r\n    line-height: 30px;\r\n    cursor: pointer;\r\n    border-radius: 4px;\r\n    position: relative;\r\n    padding: 0px 20px;\r\n    margin-right: 8px;\r\n    display: inline-block;\r\n  }\r\n  \r\n  .rDQEIaq6XCtHYqzEkGu2 input {\r\n    display: none;\r\n    opacity: 0; /* invisible but it's there! */\r\n    width: 100%;\r\n    height: 100%;\r\n    position: absolute;\r\n  }", "",{"version":3,"sources":["webpack://./src/widgets/FileUploadWidget/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;IACpB,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB,EAAE,iBAAiB;IAC1C,qBAAqB;IACrB,cAAc;IACd,gBAAgB,EAAE,mBAAmB;IACrC,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,UAAU,EAAE,8BAA8B;IAC1C,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".dropbox {\r\n    height: 30px;\r\n    color: var(--color4);\r\n    text-align: center;\r\n    text-decoration: none;\r\n    white-space: nowrap;\r\n    box-sizing: border-box;\r\n    border: 2px dashed grey; /* the dash box */\r\n    background: lightcyan;\r\n    color: dimgray;\r\n    min-height: 30px; /* minimum height */\r\n    line-height: 30px;\r\n    cursor: pointer;\r\n    border-radius: 4px;\r\n    position: relative;\r\n    padding: 0px 20px;\r\n    margin-right: 8px;\r\n    display: inline-block;\r\n  }\r\n  \r\n  .dropbox input {\r\n    display: none;\r\n    opacity: 0; /* invisible but it's there! */\r\n    width: 100%;\r\n    height: 100%;\r\n    position: absolute;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropbox": "rDQEIaq6XCtHYqzEkGu2"
};
export default ___CSS_LOADER_EXPORT___;
