// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FtT6O_TklpkE8H2uK6gM {\r\n    border-bottom: 1px solid var(--color2);\r\n    padding-bottom: 1em;\r\n}\r\n\r\n.gqdFwJmBnIrMCh0eHleS {\r\n    font-size: small;\r\n    color: var(--color3);\r\n    font-style: italic;\r\n}\r\n\r\n.THGEhJ3hPUOh2_X6xUYr {\r\n    margin-top: 0.5em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/ListPostsComponent/style.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".post {\r\n    border-bottom: 1px solid var(--color2);\r\n    padding-bottom: 1em;\r\n}\r\n\r\n.by {\r\n    font-size: small;\r\n    color: var(--color3);\r\n    font-style: italic;\r\n}\r\n\r\n.teaser {\r\n    margin-top: 0.5em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "FtT6O_TklpkE8H2uK6gM",
	"by": "gqdFwJmBnIrMCh0eHleS",
	"teaser": "THGEhJ3hPUOh2_X6xUYr"
};
export default ___CSS_LOADER_EXPORT___;
