// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U2iyzHNhn7CNDdrCZUPP {\r\n    margin: auto;\r\n    width: 1024px;\r\n    font-size: x-large\r\n}\r\n\r\nsection {\r\n    padding: 80px 0px;\r\n}\r\n\r\n.Fto28hilwFQE7IOLMpZQ {\r\n    text-align: center;\r\n}\r\n\r\n.Fto28hilwFQE7IOLMpZQ a {\r\n    margin: 0px 12px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/routes/WelcomeRoute/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".blockquoteContainer {\r\n    margin: auto;\r\n    width: 1024px;\r\n    font-size: x-large\r\n}\r\n\r\nsection {\r\n    padding: 80px 0px;\r\n}\r\n\r\n.links {\r\n    text-align: center;\r\n}\r\n\r\n.links a {\r\n    margin: 0px 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockquoteContainer": "U2iyzHNhn7CNDdrCZUPP",
	"links": "Fto28hilwFQE7IOLMpZQ"
};
export default ___CSS_LOADER_EXPORT___;
