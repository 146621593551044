// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.nN0WhKtqs2HZf7bsz3Xu {\n  margin-bottom: 1em;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.yewkBqUtqJeTq0GAMk0a {\n  background: #ddd;\n}\n\n.LkcvnLG25QyMnH2o2QhP {\n}\n\ndiv.nN0WhKtqs2HZf7bsz3Xu select {\n  width: 100%;\n  min-height: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/widgets/DropdownWidget/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AACA;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["div.field {\n  margin-bottom: 1em;\n}\n\nlabel {\n  font-weight: 600;\n  display: block;\n}\n\n.readonly {\n  background: #ddd;\n}\n\n.writeable {\n}\n\ndiv.field select {\n  width: 100%;\n  min-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "nN0WhKtqs2HZf7bsz3Xu",
	"readonly": "yewkBqUtqJeTq0GAMk0a",
	"writeable": "LkcvnLG25QyMnH2o2QhP"
};
export default ___CSS_LOADER_EXPORT___;
